@font-face {
  font-family: Tenor-Sans;
  src: url("TenorSans-Regular.5b834dfa.ttf");
}

@font-face {
  font-family: Noto-Sans;
  font-weight: 700;
  src: url("NotoSans-Bold.c8bca258.ttf");
}

@font-face {
  font-family: Noto-Sans;
  font-weight: 400;
  src: url("NotoSans-Regular.52d8ac2a.ttf");
}

@font-face {
  font-family: Noto-Sans;
  font-weight: 500;
  src: url("NotoSans-Medium.dc72ea53.ttf");
}

@font-face {
  font-family: Noto-Sans;
  font-weight: 600;
  src: url("NotoSans-SemiBold.b35a1e7b.ttf");
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.top-0 {
  top: 0;
}

.-right-16 {
  right: -4rem;
}

.-bottom-16 {
  bottom: -4rem;
}

.-left-16 {
  left: -4rem;
}

.left-0 {
  left: 0;
}

.bottom-12 {
  bottom: 3rem;
}

.top-16 {
  top: 4rem;
}

.bottom-1 {
  bottom: .25rem;
}

.right-56 {
  right: 14rem;
}

.bottom-4 {
  bottom: 1rem;
}

.right-0 {
  right: 0;
}

.-z-10 {
  z-index: -10;
}

.z-40 {
  z-index: 40;
}

.z-50 {
  z-index: 50;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mt-\[15vh\] {
  margin-top: 15vh;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-20 {
  margin-bottom: 5rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-screen {
  height: 100vh;
}

.h-64 {
  height: 16rem;
}

.h-11 {
  height: 2.75rem;
}

.h-\[2px\] {
  height: 2px;
}

.h-px {
  height: 1px;
}

.w-screen {
  width: 100vw;
}

.w-fit {
  width: fit-content;
}

.w-64 {
  width: 16rem;
}

.w-\[10vh\] {
  width: 10vh;
}

.w-full {
  width: 100%;
}

.w-11 {
  width: 2.75rem;
}

.w-7 {
  width: 1.75rem;
}

.w-2\/3 {
  width: 66.6667%;
}

.max-w-\[7rem\] {
  max-width: 7rem;
}

.max-w-4xl {
  max-width: 56rem;
}

.flex-1 {
  flex: 1;
}

.rotate-90 {
  --tw-rotate: 90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.select-none {
  user-select: none;
}

.list-inside {
  list-style-position: inside;
}

.list-disc {
  list-style-type: disc;
}

.flex-col {
  flex-direction: column;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-12 {
  gap: 3rem;
}

.gap-x-8 {
  column-gap: 2rem;
}

.gap-y-16 {
  row-gap: 4rem;
}

.space-y-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse) ) );
  margin-bottom: calc(1.25rem * var(--tw-space-y-reverse) );
}

.space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.75rem * calc(1 - var(--tw-space-y-reverse) ) );
  margin-bottom: calc(.75rem * var(--tw-space-y-reverse) );
}

.space-y-14 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(3.5rem * calc(1 - var(--tw-space-y-reverse) ) );
  margin-bottom: calc(3.5rem * var(--tw-space-y-reverse) );
}

.space-y-\[4px\] > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(4px * calc(1 - var(--tw-space-y-reverse) ) );
  margin-bottom: calc(4px * var(--tw-space-y-reverse) );
}

.space-x-14 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(3.5rem * var(--tw-space-x-reverse) );
  margin-left: calc(3.5rem * calc(1 - var(--tw-space-x-reverse) ) );
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse) ) );
  margin-bottom: calc(1rem * var(--tw-space-y-reverse) );
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.rounded-lg {
  border-radius: .5rem;
}

.bg-bg {
  --tw-bg-opacity: 1;
  background-color: hsl(42 48% 95% / var(--tw-bg-opacity) );
}

.bg-primary {
  --tw-bg-opacity: 1;
  background-color: hsl(234 20% 30% / var(--tw-bg-opacity) );
}

.py-24 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-14 {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
}

.pl-5 {
  padding-left: 1.25rem;
}

.pt-\[20vh\] {
  padding-top: 20vh;
}

.text-right {
  text-align: right;
}

.text-start {
  text-align: start;
}

.font-NotoSans {
  font-family: Noto-Sans, sans-serif;
}

.font-TenorSans {
  font-family: Tenor-Sans, sans-serif;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.font-normal {
  font-weight: 400;
}

.uppercase {
  text-transform: uppercase;
}

.text-primary {
  --tw-text-opacity: 1;
  color: hsl(234 20% 30% / var(--tw-text-opacity) );
}

.text-opacity-100 {
  --tw-text-opacity: 1;
}

.text-opacity-75 {
  --tw-text-opacity: .75;
}

.underline {
  text-decoration-line: underline;
}

.underline-offset-1 {
  text-underline-offset: 1px;
}

.mix-blend-multiply {
  mix-blend-mode: multiply;
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.mobile-menu_link {
  font-family: Tenor-Sans, sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2rem;
}

.underline_link {
  position: relative;
}

.underline_link:before {
  height: 2px;
  width: 0;
  --tw-bg-opacity: 1;
  background-color: hsl(234 20% 30% / var(--tw-bg-opacity) );
  content: var(--tw-content);
  transition-property: all;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  position: absolute;
  bottom: .75rem;
  right: 0;
}

.underline_link:hover:before {
  content: var(--tw-content);
  width: 100%;
}

.scroll-lock {
  height: 100vh;
  overflow: hidden;
}

.section__h2 {
  font-family: Tenor-Sans, sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2rem;
}

@media (min-width: 480px) {
  .section__h2 {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
}

@media (min-width: 760px) {
  .section__h2 {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
}

.before\:absolute:before {
  content: var(--tw-content);
  position: absolute;
}

.before\:-bottom-10:before {
  content: var(--tw-content);
  bottom: -2.5rem;
}

.before\:h-px:before {
  content: var(--tw-content);
  height: 1px;
}

.before\:w-\[60\%\]:before {
  content: var(--tw-content);
  width: 60%;
}

.before\:bg-primary:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: hsl(234 20% 30% / var(--tw-bg-opacity) );
}

.hover\:text-secoundary:hover {
  --tw-text-opacity: 1;
  color: hsl(37 79% 75% / var(--tw-text-opacity) );
}

@media (min-width: 480px) {
  .tablet\:-top-16 {
    top: -4rem;
  }

  .tablet\:-bottom-24 {
    bottom: -6rem;
  }

  .tablet\:right-80 {
    right: 20rem;
  }

  .tablet\:-bottom-16 {
    bottom: -4rem;
  }

  .tablet\:h-96 {
    height: 24rem;
  }

  .tablet\:h-80 {
    height: 20rem;
  }

  .tablet\:w-2\/3 {
    width: 66.6667%;
  }

  .tablet\:w-96 {
    width: 24rem;
  }

  .tablet\:w-80 {
    width: 20rem;
  }

  .tablet\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .tablet\:space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse) ) );
    margin-bottom: calc(1.5rem * var(--tw-space-y-reverse) );
  }

  .tablet\:py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .tablet\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .tablet\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .tablet\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .tablet\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .tablet\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .tablet\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }
}

@media (min-width: 760px) {
  .md\:block {
    display: block;
  }

  .md\:hidden {
    display: none;
  }

  .md\:h-\[30rem\] {
    height: 30rem;
  }

  .md\:w-\[30rem\] {
    width: 30rem;
  }

  .md\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .md\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .md\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .md\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .md\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .md\:before\:h-1:before {
    content: var(--tw-content);
    height: .25rem;
  }
}

@media (min-width: 1024px) {
  .lg\:w-1\/2 {
    width: 50%;
  }

  .lg\:max-w-4xl {
    max-width: 56rem;
  }

  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .lg\:gap-x-12 {
    column-gap: 3rem;
  }

  .lg\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .lg\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

@media (min-width: 1200px) {
  .xl\:ml-28 {
    margin-left: 7rem;
  }

  .xl\:flex {
    display: flex;
  }

  .xl\:w-1\/2 {
    width: 50%;
  }

  .xl\:w-2\/3 {
    width: 66.6667%;
  }

  .xl\:max-w-6xl {
    max-width: 72rem;
  }
}

/*# sourceMappingURL=index.a80cc34b.css.map */
