/* fonts start */
@font-face {
  font-family: "Tenor-Sans";
  src: url(./assests/fonts/Tenor_Sans/TenorSans-Regular.ttf);
}
@font-face {
  font-family: "Noto-Sans";
  font-weight: 700;
  src: url(./assests/fonts/Noto_Sans/NotoSans-Bold.ttf);
}
@font-face {
  font-family: "Noto-Sans";
  font-weight: 400;
  src: url(./assests/fonts/Noto_Sans/NotoSans-Regular.ttf);
}
@font-face {
  font-family: "Noto-Sans";
  font-weight: 500;
  src: url(./assests/fonts/Noto_Sans/NotoSans-Medium.ttf);
}
@font-face {
  font-family: "Noto-Sans";
  font-weight: 600;
  src: url(./assests/fonts/Noto_Sans/NotoSans-SemiBold.ttf);
}

/* fonts end */

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .mobile-menu_link {
    @apply text-2xl font-TenorSans font-bold;
  }
  .underline_link {
    @apply relative before:w-0 before:h-[2px] before:right-0 before:bottom-3 before:bg-primary before:absolute before:transition-all before:duration-300 hover:before:w-full;
  }
  .scroll-lock {
    @apply overflow-hidden h-screen;
  }
  .section__h2 {
    @apply text-2xl tablet:text-3xl md:text-4xl font-TenorSans font-bold;
  }
}
